<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M12.718 10.0781C13.365 9.938 14.0387 9.98708 14.6585 10.2195L14.2116 11.4112L14.6585 10.2195L35.7627 18.1336C36.4227 18.3811 36.9909 18.8249 37.3909 19.4052C37.7909 19.9855 38.0035 20.6744 37.9999 21.3792C37.9964 22.084 37.777 22.7708 37.3712 23.3471C36.9654 23.9233 36.3928 24.3614 35.7304 24.6023L27.5698 27.5698L24.6023 35.7304C24.3614 36.3928 23.9233 36.9654 23.3471 37.3712C22.7708 37.777 22.084 37.9964 21.3792 38C20.6744 38.0035 19.9854 37.7909 19.4051 37.3909C18.8249 36.9909 18.3811 36.4227 18.1336 35.7627L10.2195 14.6585L11.4112 14.2116L10.2195 14.6585C9.98708 14.0387 9.938 13.365 10.0781 12.718C10.2183 12.071 10.5417 11.478 11.0099 11.0099C11.478 10.5417 12.071 10.2183 12.718 10.0781ZM13.7648 12.6029C13.6025 12.5421 13.4262 12.5292 13.2568 12.5659C13.0875 12.6026 12.9323 12.6873 12.8098 12.8098C12.6873 12.9323 12.6026 13.0875 12.5659 13.2568C12.5292 13.4262 12.5421 13.6025 12.6029 13.7648L20.517 34.869L19.3412 35.3099L20.517 34.869C20.5818 35.0417 20.6979 35.1904 20.8498 35.2951C21.0017 35.3998 21.182 35.4555 21.3665 35.4545C21.551 35.4536 21.7307 35.3962 21.8815 35.29L22.6143 36.3306L21.8815 35.29C22.0324 35.1838 22.147 35.0339 22.2101 34.8605L25.3805 26.1417C25.5092 25.7879 25.7879 25.5092 26.1417 25.3805L34.8605 22.2101C35.0339 22.147 35.1837 22.0324 35.29 21.8815L36.3306 22.6143L35.29 21.8815C35.3962 21.7307 35.4536 21.551 35.4545 21.3665C35.4554 21.182 35.3998 21.0017 35.2951 20.8498C35.1904 20.6979 35.0417 20.5818 34.869 20.517L35.3158 19.3253L34.869 20.517L13.7648 12.6029Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-new-item-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
